@import 'abstract/_mixins';
		 @import 'abstract/_variables';
		 @import 'abstract/_functions';
		 @import 'abstract/_typography';

.icon {
    display: grid;
    justify-content: center;
    align-items: center;

}
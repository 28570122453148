@import 'abstract/_mixins';
		 @import 'abstract/_variables';
		 @import 'abstract/_functions';
		 @import 'abstract/_typography';
.LocalWebsiteSelectorSection {
    @include contained;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    gap: rem(0);
    padding: rem(64) 0;
    justify-content: space-between;

    @media (min-width: 950px) {
        padding: rem(96) rem(72);
        flex-direction: row;
        gap: rem(96);
    }
    
    .leftSideContent {
        max-width: rem(760);
        flex: 1;
        padding: rem(64) rem(24);

        h3 {
            margin: 0 0 rem(32);
        }

        p {
            font-size: 16px;

            a {
                @include underlinedLink;
            }
        }

        ul {
            padding: 0 0 0 rem(20);

            li {
                line-height: 150%;
            }
        }

        a {
            margin: rem(32) 0 0;
            width: 100%;

            @include mobile {
                width: fit-content;
            }
        }

        @media (min-width: 950px) {
            padding: 0;
        }
    }
    
}
.LocalWebsiteSelector {
    background-color: $primary-fog-900; 
    padding: rem(44) rem(32);
    display: flex;
    flex-direction: column;
    max-width: rem(472);
    //min-width: rem(400);

    flex: 1;
    margin: 0 auto;

    @include tablet {
        margin: 0;
    }
    
     > * {
        color: $primary-midnight-900;
    }

    
    .title {
        font-size: rem(28);
        line-height: 150%;
        margin: 0 0 rem(32);
        font-weight: 700;
    }
    
    .subtitle {
        font-size: rem(19);
        font-weight: 600;
        margin: 0 0 rem(42);
    }

    .description {
        margin: 0 0 rem(32); 
    }
    
    label {
        font-size: rem(16);
        font-weight: 600;
    }

    .trigger {
        margin: rem(8) 0 rem(16);
        width: 100%;
        font-size: 13px;
        color: $primary-midnight-700;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: $shade-white;
        border: 1px solid $primary-fog-900;
        border-radius: 2px;
        padding: rem(8) rem(12);
    
        svg {
            width: rem(24);
            height: rem(24);
        }
    }
    

    button,a {
        width: 100%;
        @include mobile {
            width: fit-content;
        }
    }

    .link {
        font-size: 16px;
        font-weight: 400;

        span {
            font-weight: 600;
        }
    }
}



.content {
    border-radius: 2px;
    background-color: white;
    z-index: 1000;

    .viewport {
        padding: rem(8) rem(12);

        &:hover {
            border: none;
        }
    }

    .item {
        cursor: pointer;
        padding: rem(8) rem(12);
        transition: all 0.25s ease;

        &:hover {
            background-color: $primary-fog-900;
        }
    }
}


.RegionWebsiteSelectorModal {
    background-color: $primary-fog-900;
    box-sizing: border-box;
    padding: rem(44) rem(32);
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 0 auto;

    &.onMobile {
        padding: rem(24);
    }

    @include tablet {
        margin: 0;
    }
    
     > * {
        color: $primary-midnight-900;
    }

    .title {
        font-size: rem(28);
        line-height: 150%;
        margin: 0 0 rem(32);
        font-weight: 700;
    }
    
    .subtitle {
        font-size: rem(19);
        font-weight: 600;
        margin: 0 0 rem(42);

        &.onMobile {
            font-size: rem(16);
            margin: 0 0 rem(32);
            
        }
    }

    .description {
        margin: 0 0 rem(32); 
    }
    
    label {
        font-size: rem(16);
        font-weight: 600;
    }

    .select {
        width: 100%;
    }

    .trigger {
        margin: rem(8) 0 rem(16);
        width: 100%;
        font-size: 13px;
        color: $primary-midnight-700;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: $shade-white;
        border: 1px solid $primary-fog-900;
        border-radius: 2px;
        padding: rem(8) rem(12);
    
        svg {
            width: rem(24);
            height: rem(24);
        }
    }
    

    button,a {
        width: 100%;
    }

    .link {
        font-size: 16px;
        font-weight: 400;

        span {
            font-weight: 600;
        }
    }
}



.content {
    width: var(--radix-select-trigger-width);
    border-radius: 2px;
    background-color: white;
    z-index: 1000;

    .viewport {
        padding: rem(8) rem(12);

        &:hover {
            border: none;
        }
    }

    .item {
        cursor: pointer;
        padding: rem(8) rem(12);
        transition: all 0.25s ease;

        &:hover {
            background-color: $primary-fog-900;
        }
    }
}

@import 'abstract/_mixins';
		 @import 'abstract/_variables';
		 @import 'abstract/_functions';
		 @import 'abstract/_typography';
.icon {
	fill: $primary-midnight-500;

	&.direction {
		&--right {
			transform: rotate(180deg);
		}

		&--up {
			transform: rotate(90deg);
		}

		&--down {
			transform: rotate(-90deg);
		}

		&--next {
			transform: rotate(180deg);
			right: 0
		}

		&--previous {
			left: 0
		}

	}

}


@import 'abstract/_mixins';
		 @import 'abstract/_variables';
		 @import 'abstract/_functions';
		 @import 'abstract/_typography';
.btn {
	box-sizing: border-box;
	font-family: inherit;
	border: solid 1px transparent;
	border-radius: rem(4);
	padding: rem(9) rem(24);
	font-size: rem(16);
	font-weight: 600;
	text-decoration: none;
	cursor: pointer;
	text-align: center;
	min-height: rem(48);
	display: inline-flex;
	align-items: center;
	justify-content: center;
	gap: rem(8);
	width: fit-content;

	&:hover {
		transition: 300ms ease-in-out;
	}

	&.primary {
		background-color: $primary-cardinal-900;
		color: $shade-white;

		&:hover {
			background-color: $shade-white !important;
			color: $primary-cardinal-900 !important;
			border-color: $primary-cardinal-900 !important;
		}

		&:active,
		&.active {
			color: $shade-white;
			background-color: $primary-midnight-900;
			border-color: $primary-midnight-900;
			transition: none;
		}

		&:focus,
		&:focus-within,
		&:focus-visible {
			background-color: $primary-cardinal-900;
			border-color: $information-300;
			outline: 2px solid $information-300;
		}

		&[disabled],
		&:disabled {
			cursor: not-allowed;
			background-color: $primary-cardinal-300 !important;
			border-color: $primary-cardinal-300 !important;
			color: $shade-white !important;
		}
	}

	&.secondary {
		border-color: $secondary-steel-900;
		background-color: $shade-white;
		color: $secondary-steel-900;

		&:hover {
			background-color: $secondary-steel-900 !important;
			color: $shade-white !important;
		}

		&:active,
		&.active {
			color: $shade-white;
			background-color: $primary-midnight-900;
			border-color: $primary-midnight-900;
			transition: none;
		}

		&:focus,
		&:focus-within,
		&:focus-visible {
			color: $primary-midnight-900;
			background-color: $shade-white;
			border-color: $information-300;
			outline: 2px solid $information-300;
		}

		&[disabled],
		&:disabled {
			cursor: not-allowed;
			color: $secondary-steel-300 !important;
			border-color: $secondary-steel-300 !important;
			background-color: $shade-white !important;
		}
	}

	&.tertiary {
		border-color: $primary-fog-900;
		background-color: $primary-fog-900;
		color: $primary-midnight-900;
		font-weight: 400;

		&:hover {
			background-color: $primary-fog-900 !important;
			background-color: $shade-white !important;
		}

		&:active,
		.active {
			color: $shade-white;
			background-color: $primary-midnight-900;
			border-color: $primary-midnight-900;
			transition: none;
		}

		&:focus,
		&:focus-within,
		&:focus-visible {
			border-color: $information-300;
			outline: 2px solid $information-300;
		}

		&[disabled],
		&:disabled {
			cursor: not-allowed;
			background-color: $primary-fog-300 !important;
			color: $primary-midnight-300 !important;
			border-color: $primary-fog-300 !important;
		}
	}

	&.rounded {
		border-color: $primary-cardinal-900;
		background-color: $shade-white;
		color: $primary-midnight-900;
		border-radius: rem(50);

		&:hover {
			color: $primary-cardinal-900 !important;
			background-color: $shade-white !important;
		}

		&:active,
		.active {
			color: $shade-white;
			background-color: $primary-midnight-900;
			border-color: $primary-midnight-900;
			transition: none;
		}

		&:focus,
		&:focus-within,
		&:focus-visible {
			color: $primary-midnight-900;
			background-color: $shade-white;
			border-color: $information-300;
			outline: 2px solid $information-300;
		}

		&[disabled],
		&:disabled {
			cursor: not-allowed;
			color: $primary-midnight-300 !important;
			border-color: $primary-cardinal-300 !important;
			background-color: $shade-white !important;
		}
	}

	&.empty {
		background-color: transparent;
		color: $secondary-steel-900;
		border: none;
		padding: 0;
		margin: 0;
		font-weight: 400;
		font-size: inherit;
		min-height: unset;
		text-decoration: underline;

		&:focus,
		&:focus-within,
		&:focus-visible {
			border-color: $information-300;
			outline: 2px solid $information-300;
		}

		&[disabled],
		&:disabled {
			cursor: not-allowed !important;
		}
	}

}

@import 'abstract/_mixins';
		 @import 'abstract/_variables';
		 @import 'abstract/_functions';
		 @import 'abstract/_typography';
.FooterSocials {
    .FooterInnerBottomSocial {
        display: flex;
        justify-content: space-between;
        margin:rem(16) 0 0;
        flex-direction: column;

        @include mobile {
            flex-direction: row;
            align-items: center;
            margin:rem(24) 0;
        }

        button {

            @include underlinedLink;
            color: $secondary-steel-300;
            padding: 0;
            text-align: left;
            margin:rem(16) 0 0;

            @include mobile {
                text-align: center;
                margin: 0;
            }
        }

        ul {
            display: flex;
            gap: rem(24);
            justify-content: flex-start;
            margin: rem(32) 0;

            @include mobile {
                margin: 0;
                justify-content: flex-end;
            }
        }
    }
}

@import 'abstract/_mixins';
		 @import 'abstract/_variables';
		 @import 'abstract/_functions';
		 @import 'abstract/_typography';
.footer {
    background-color: $primary-midnight-900;

    * {
        color: $primary-fog-900;
    }

    nav {
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
        }
    }

    a {
        @include footerLinkPrimary;
    }
}

.footerContainer {
    margin: rem(48) rem(24);
    
    @include mobile {
        @include contained;
        margin-top: rem(64);
        margin-bottom: rem(64);
    } 
}

.footerHeaderLinks {
    margin: 0 0 rem(16);

    @include mobile {
        margin: 0 0 rem(32);
    }
    @include tablet {
        &.isECom {
            display: flex;
            justify-content: space-between;
        }
    }   

}

.footerMainContent {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: rem(28);

    @include mobile {
        gap: rem(64);
        flex-direction: row;
    }
}

.footerLinkBlocksWrapper {
    display: flex;
    flex-direction: column;
    gap: rem(32);

    @include mobile {
        gap: rem(48);
    }
    
    
    &.isECom {
        @include mobile {
            gap: rem(48);
        }
        nav {
            border-bottom: 1px solid $primary-fog-900;
    
            @include mobile {
                li:last-child {
                    margin: 0;
                }
                border-bottom: 0 solid $primary-fog-900;
            }
        }
    }

    
    @include mobile {
        flex-direction: row;
        gap: 4rem;
    }
}
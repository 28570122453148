@import 'abstract/_mixins';
		 @import 'abstract/_variables';
		 @import 'abstract/_functions';
		 @import 'abstract/_typography';
.burgerButton {
  display: block;
  width: rem(32);
  height: rem(32);
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;

  &:focus,
  &:focus-within,
  &:focus-visible {
    outline: 2px solid $information-300;
  }

  svg path {
    stroke: $primary-midnight-500;
  }

  &:hover {
    svg path {
      stroke: $primary-cardinal-900;
    }
  }

  &.lines {
    svg path {
      stroke: $primary-midnight-900;
    }
    &:hover {

      svg path {
        stroke: $secondary-steel-900;
      }
    }
  }

  @include tablet {
    display: none;
  }
}

@import 'abstract/_mixins';
		 @import 'abstract/_variables';
		 @import 'abstract/_functions';
		 @import 'abstract/_typography';
.Banner {
	width: 100%;
	display: flex;
	justify-content: center;
	flex-direction: row;
	background-image: url('../../app/assets/images/img/background.png?format=webp');
	background-position: center;
	background-size: cover;
	padding: rem(48) 0;

	@media only screen and (max-width: 685px) {
		padding: rem(32) rem(24);
	}

	&Inner {
		width: 100%;
		max-width: rem(472);
		display: flex;
		justify-content: flex-start;
		flex-direction: row;
		flex-wrap: wrap;
		color: $primary-midnight-900;

		h3 {
			color: $primary-midnight-900;
			font-size: rem(23);
			font-style: normal;
			font-weight: 700;
			line-height: 1.5;
			margin: 0;
		}

		p {
			color: $primary-midnight-700;
			font-size: rem(16);
			font-style: normal;
			font-weight: 400;
			line-height: 1.5;
			margin: rem(24) 0;
			width: 100%;
		}

		input {
			flex: 1;
			border: 0;
			padding: 0 rem(16);
			color: $primary-midnight-700;
			font-size: rem(16);
			font-style: normal;
			font-weight: 400;
			line-height: 1.5;
		}
	}
}

@import 'abstract/_mixins';
		 @import 'abstract/_variables';
		 @import 'abstract/_functions';
		 @import 'abstract/_typography';
.ScrollButton {
	width: rem(44);
	height: rem(44);
	border-radius: 50%;
	background-color: $primary-fog-900;
	position: fixed;
	border: 0;
	left: rem(8);
	bottom: rem(8);
	z-index: 100;

	cursor: pointer;
	opacity: 0;
	transition: 0.25s;
	pointer-events: none;
	box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.15);

	@include tablet {
        left: rem(16);
        bottom: rem(16);
	}

	&.Show {
		opacity: 1;
		pointer-events: all;
	}

	svg {
		width: rem(24);
		height: rem(24);

		fill: $primary-midnight-900;
	}

	&:hover {
		background-color: $primary-cardinal-900;
		svg {
			fill: $shade-white;
		}
	}

	&:active {
		background-color: $primary-midnight-900;
		svg {
			fill: $shade-white;
		}
	}
}

@import 'abstract/_mixins';
		 @import 'abstract/_variables';
		 @import 'abstract/_functions';
		 @import 'abstract/_typography';
.footerHeader {
    p,
    h3 {
        margin: 0;
        padding: 0;
        font-size: 1rem;
    }

    img {
        margin-bottom: 2rem;
    }

    p {
        margin-bottom: rem(14);
    }

    h3 {
        @include paragraph02
    }

    address {
        width: 27ch;
        font-weight: 400;
        font-style: normal;
    }

    .secondaryLink {
        @include footerLinkSecondary;
        margin-bottom: rem(20);
        display: block;
    }
}


.footerPhoneNumber {
    display: inline-block;
    margin-bottom: rem(3);
}

@import 'abstract/_mixins';
		 @import 'abstract/_variables';
		 @import 'abstract/_functions';
		 @import 'abstract/_typography';

.AccordionTrigger {
    font: unset;
    font-size: rem(19);
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    width: 100%;
    text-align: left;
    background: none;
    border: none;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 rem(24);

    svg {
        width: rem(30);
        height: rem(30);
        fill: $primary-fog-900;
        transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);
        
        @include mobile {
            display: none;
        }
    }
}

.AccordionTrigger[data-state='open'] {
    svg {
        transform: rotate(90deg);
    }
}

.footerLinksCollapsibleContent {
        margin-bottom: rem(16);
}
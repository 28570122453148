@import 'abstract/_mixins';
		 @import 'abstract/_variables';
		 @import 'abstract/_functions';
		 @import 'abstract/_typography';

.overlay {
    @include applyOverlay;
}

.content {
    background-color: white;
    box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90vw;
    max-width: 450px;
    max-height: 85vh;
    padding: 32px 24px;
    animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 115;

    & .close {
        position: absolute;
        margin: rem(12);
        right: 0;
        top:0;
        height: 24px;
        aspect-ratio: 1;
        cursor: pointer;

        & path {
            stroke: $primary-midnight-500;
        }

        &:hover path {
            stroke: $primary-cardinal-900;
        }
    }

    & .closeLight {
        position: absolute;
        margin: rem(12);
        right: 0;
        top:0;
        height: 24px;
        aspect-ratio: 1;
        cursor: pointer;

        & path {
            stroke: $shade-white;
        }

        &:hover path {
            stroke: $primary-cardinal-900;
        }
    }
}

.title {
    @include h1;
}
